/* eslint-disable object-shorthand */

export const PACKAGE_BUILD_DATE = '2025-01-04T01:57:37.540Z';
export const PACKAGE_NAME = 'invision-customercare';
export const PACKAGE_VERSION = '24.4.10501-1674439';

export default {
    PACKAGE_BUILD_DATE: PACKAGE_BUILD_DATE,
    PACKAGE_NAME: PACKAGE_NAME,
    PACKAGE_VERSION: PACKAGE_VERSION
};
